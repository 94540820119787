/* --------------------------------------------------------
Style Sheet for the Name.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- Name of the Avatar
----------------------------------------------------------*/

.nameWidth {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}