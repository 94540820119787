/* --------------------------------------------------------
Style Sheet for the SearchBar.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- Search Bar
----------------------------------------------------------*/

/* Search Bar */
/* ---------- */

.search-container {
    color: var(--primary);
    background: var(--primaryButton);
    width: calc(100% - 20px);
    height: 40px;
    border-radius: 5px;
    z-index: 3;
    display: flex;
    position: relative;
    margin: 0 auto; /* Center it within the box */
  }

.search-text {
    font: inherit;
    background-color: transparent;
    color: inherit;
    width: calc(100% - 40px);
    height: 100%;
    border: 0;
    padding: 0px 5px 0px 5px;
}

::-webkit-input-placeholder {
    color: inherit;
}

::-moz-placeholder {
    color: inherit;
}

:-ms-input-placeholder {
    color: inherit;
}

::placeholder {
    color: inherit;
}

.search-button {
    right: 0;
    top: 0;
    width: 40px;
    color: #fff;
    
    height: 40px;
       
}

.search-button:hover {
    cursor: pointer;
    background: rgb(180, 192, 199);
    border-radius: 3px;
}
