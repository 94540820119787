main {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  padding: 20px 30px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  overflow: hidden;
  transition: 0.5s;
}

.FactoryApp {
  width: 20px;
}

.banner {
  position: relative;
  width: 80%;
  max-width: 984px;
  height: 100%;
  border-radius: 10px;
  background: var(--bannerBG); /* Semi-transparent white for frosted glass effect */
  transition: 0.5s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6); /* Drop shadow */
  backdrop-filter: blur(4px); /* Blurs the background behind the banner */
}

.banner.active {
  position: relative;
  width: 93%;
  transition: 0.5s;
}

.menuArea {
  position: relative;
  transition: 0.5s;
  height: 100%;
  width: 25%;
  max-width: 300px;
  display: contents;
  
}
.menuArea.active {
width:100px;

}

.menuArea.isVisible {
  position: relative;
  width: 10%;
  transition: 0.5s;

}

section {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 100vh;
  padding: 100px 5px 0 5px;
  bottom: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  transform-origin: bottom;
  z-index: 500;
}

section::-webkit-scrollbar {
  display: none;
}

section.active {
  top: 0px;
  height: auto;
  overflow-y: visible;
  opacity: 1;
  padding-top: 80px;
}

@media (max-width: 1275px) {
  main {
    padding: 20px 30px 20px 20px;
  }

  .banner {
    width: 100%;
  }

  .banner.active {
    width: 100%;
  }

  section {
    padding: 5px;
  }
}


