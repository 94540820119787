

.dltitle {
    position: relative;
    top: 20px;
    padding: 20px;
}

.dlcard {
    background: var(--primary);
    width: 300px;
    display: inline-block;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4); 
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.close-button {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: #f44; /* Red background color, you can change it as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    right:0;
    position: absolute;
    margin: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 2;
  }
  
  .close-button:hover {
    background-color: #c33; /* Darker shade when hovered */
  }
  
  /* FontAwesome Icon Style */
  .fa-times-circle {
    color: white; /* Icon color */
    font-size: 18px; /* Icon size relative to the button */
  }
  

.closeImg {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: var(--primary);
    border-radius: 10px;
}

.dl-card__image {
    position: relative;
    height: 230px;
    margin-bottom: 35px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.dl-card__level {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
}



.dl-card__unit-name {
    font-size: 26px;
    color: black;
    font-weight: 900;
    margin-bottom: 5px;
}

.dl-card__unit-description {
    padding: 20px;
    margin-bottom: 10px;
}

.dl-cardButton {
    background: #ffb101;
    color: rgb(0, 0, 0);
    font-weight: 700;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;

}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.close {
    width: inherit;
    position: absolute;
    top: 25%;
    background: none !important;
    border: none !important;
    box-shadow: 0px 0px 0px 0px black !important;
}
.timer {
    font-size: 1.2rem;
}