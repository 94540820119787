/* Universal Input Styles */
input {
    padding: 8px 12px;
    border: 1px solid var(--primaryButtonBorder);
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    background-color: var(--primary);
    outline: none;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-weight: lighter;
}
  
  input:focus {
    border-color: var(--primaryButtonBorder); 
  }
  
  button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    background-color: var(--primary);
    border: 1px solid #525252;
    cursor: none;
    outline: none;
    transition: 0.3s;
    border-radius: 10px;
  }
  
  button:hover {
    border: 1px solid var(--secondary);
    background-color: var(--secondary);
    box-shadow: 
    0px 4px 6px rgba(0, 0, 0, 0.4),
    0 0 15px var(--secondary);
  }
  
  button:disabled {
    background-color: #202020;
    cursor: not-allowed;
  }
  

  .modalBackdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.205);
    z-index: 10;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 10px;
}

.modalContent {
  box-sizing: content-box;
  height: fit-content;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary);
  font-size: 1.4em;
  border-radius: 10px;
  position: absolute;
  display: inline-block;
  padding: 40px 40px 40px 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.modalTitle {
  color: var(--secondary);
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.788);
  position: relative;
  padding-bottom: 15px;
  text-transform: uppercase;
  font-style: italic;
  font-size: xx-large;
}

.modalText {
  font-size: x-large;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.788);
  position: relative;
  bottom: 10px;
}

.myShadow {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.invisascroll {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.invisascroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.extendo {

}

.componentsubtext {
  font-size:medium;
}
.textError {
  color: red;
}