.shopBagTable {
  margin-top: 50px;
  color: #ffffff;
  border-radius: 10px;
  background: var(--bgColor);

}

.shopBagTable thead tr {
  font-size: 1.3rem;
}

.shopBagTable thead tr th {
  background: transparent;
  color: #ffffff;
}

.itemCount {
  font-size: 1.3rem;
}

.payment {
  display: inline-flex;
  align-items: center;
  gap: 50px;
  font-size: 1.3rem;
  color: #ffffff;
}

.payment a {
  padding: 10px 20px;
  text-decoration: none;
  color: #ffffff;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: var(--bgColor);

}
