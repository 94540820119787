.mobileloadscreen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 5px 5px 0 5px;
    background-color: var(--primary);
}

.mobilecontainer {
    display: block;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: auto;
    padding-top: 5px;
    position: relative;
    top: 50%; /* Set the top edge to 50% from the top of the nearest positioned ancestor */
    left: 50%; /* Center horizontally: move the left edge to the middle */
    transform: translate(-50%, -50%) scale(80%); /* Move it back by half its own width and height to center, and scale down */
}

.logoStyle {
    width: calc(100% - 10px); /* Full width minus padding */
    height: auto;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
}

.mobileCentering {
    flex: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.mobilethree-canvas {
    width: 100%; /* Full width of the container */
    height: 20vh; /* Adjust height as needed */
    z-index: 1;
    left: 50%; /* Center horizontally: move the left edge to the middle */
    transform: translatex(-50%) scale(80%); /* Move it back by half its own width and height to center, and scale down */
}

.footer-text {
    text-align: center;
    height:25%;
    width: 100%;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%) scale(80%);
    font-size: clamp(12px, 2vw, 18px);
    padding: 1vw; /* Optional: Adds padding inside the container */
    box-sizing: border-box; /* Ensures padding is included in the width/height calculations */
}

@media (max-width: 600px) { /* Adjusting for smaller screens */
    .footer-text {
        font-size: 4vw; /* Increases font size for small screens */
    }
}


.custom-btn {
    margin-top: 20px; /* Space above the button */
}
