.centering {
  left: 50%; /* Center horizontally */
}

.login {
    border-radius: 10px;
    border: 1px solid var(--primaryButtonBorder);
    background: var(--primaryButton);
    color: #fff;
    padding: 10px 20px;
    width: auto;
    white-space: nowrap;
}

.signOut {
    background: #af0c00;
}

.signOut:hover{
  background: #ff1100;
}

.loginContainer {
    position: relative;
    display: grid;
    margin: 5px auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
}



.profsep {
    border-radius: 10px;
    padding: 20px;
    margin:  5px 0px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    background: rgba(0, 0, 0, 0.5);
}

.credbal {
   
    padding: 5px 0px 0px 5px;
    background-image: url("https://www.transparenttextures.com/patterns/gplay.png"),
    radial-gradient(
    #ffd000,
    #f89620
   );
   
}

.credit-title {
  font-size: 2em;
  font-weight: 900;
  text-transform: uppercase;

  top: 15px;
  position: relative;
}

.credit-balance {
  font-size: 3em;
  font-weight: 900;

}

.profsep.credbal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* This ensures that the text itself is centered as well */
  
}


.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #131314;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #e3e3e3;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 200px;
  max-width: 400px;
  min-width: min-content;
  border-color: #8e918f;
  left: 50%;
  transform: translateX(-50%);
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #13131461;
  border-color: #8e918f1f;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #e3e3e31f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: white;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: white;
  opacity: 8%;
}

  
  .profsep > div, .profsep > p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adds some space between the rows */
  }
  
  .profsep > div > label {
    margin-right: auto; /* Ensures the label is pushed to the left */
  }
  
  .profsep > div > input {
    margin-left: auto; /* Ensures the input is pushed to the right */
  }
  

  .profile-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .user-info, .right-side-info {
    display: flex;
    flex-direction: column;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 768px) {
    .profile-grid {
      grid-template-columns: 1fr;
    }
  }
  
  .header-with-buttons {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    justify-content: space-between; /* Pushes the h2 to the left and action-buttons to the right */
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .action-buttons button {
    margin-left: 10px; /* Adds spacing between buttons */
    width: fit-content;
  }
  








  main.table {
    width: inherit;
    height: inherit;
    max-height: 100vh;
    background-color: var(--primary);
    backdrop-filter: blur(7px);
    box-shadow: 0 .4rem .8rem #0005;
    border-radius: .8rem;
    overflow: hidden;

}

.table__header {
    width: 100%;
    height: 10%;
    background-color: #fff4;
    padding: .8rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table__header .input-group {
    width: 35%;
    height: 100%;
    background-color: var(--primary);
    padding: 0 .8rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
}

.table__header .input-group:hover {
    width: 45%;
    background-color: var(--primary);
    box-shadow: 0 .1rem .4rem #0002;
}

.table__header .input-group img {
    width: 1.2rem;
    height: 1.2rem;
}

.table__header .input-group input {
    width: 100%;
    padding: 0 .5rem 0 .3rem;
    background-color: transparent;
    border: none;
    outline: none;
}

.table__body {
    width: 95%;
    max-height: calc(89% - 1.6rem);
    background-color: var(--primary);
    margin: .8rem auto;
    border-radius: 10px;
    overflow: auto;
    overflow: overlay;
    border: 1px solid var(--primaryButtonBorder);
}


.table__body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb{
    border-radius: .5rem;
    background-color: #0004;
    visibility: hidden;
}

.table__body:hover::-webkit-scrollbar-thumb{ 
    visibility: visible;
}


table {
    width: 100%;
    background-color: var(--primary);
    
}

td img {
    width: 75px;
    height: 75px;
    margin-right: .5rem;
    border-radius: 10px;
    vertical-align: middle;
    border: 2px solid #fff;
}

table, th, td {
    border-collapse: collapse;
    padding: 0.5rem;
    text-align: left;
    color: #fff;
    
}

.tableImgComp {
width: 100px;
}
.tableTextComp {
 white-space: nowrap;
 text-align: center;
 position: relative;
}

thead th {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--primary);
    cursor: pointer;
    text-transform: capitalize;
    color: #fff;
}

tbody tr:nth-child(even) {
    background-color: var(--primary);
}

tbody tr:nth-child(odd) {
  background-color: #363636;
}

tbody tr {
    --delay: .1s;
    transition: .5s ease-in-out var(--delay), background-color 0s;
}

tbody tr.hide {
    opacity: 0;
    transform: translateX(100%);
}

tbody tr:hover {
    
}

tbody tr td,
tbody tr td p,
tbody tr td img {
    transition: .2s ease-in-out;
}

tbody tr.hide td,
tbody tr.hide td p {
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: .2s ease-in-out .5s;
}

tbody tr.hide td img {
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}


thead th span.icon-arrow {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 1.4px solid transparent;
    text-align: center;
    font-size: 1rem;
    margin-left: .5rem;
    transition: .2s ease-in-out;
}

thead th:hover span.icon-arrow{
    border: 1.4px solid var(--secondary);
}

thead th:hover {
  color: #fff;
}

thead th.active span.icon-arrow{
    background-color: #6c00bd;
    color: #fff;
}

thead th.asc span.icon-arrow{
    transform: rotate(180deg);
}

thead th.active,tbody td.active {
    color: #6c00bd;
}

.img-fluid {
width: 100px;
}