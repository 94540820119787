/* --------------------------------------------------------
Style Sheet for the Editor.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- Editor controls
----------------------------------------------------------*/


.flex-container {
    display: flex;
    flex-wrap: nowrap;
    background-image: linear-gradient(to right, #1e1e1e, #1e1e1e);
}

.numeric-input {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
}

.controls {
    position: absolute;
    height: calc(100% - 10px);
    top: 0;
    left: 2px;
    width: calc(100% - 10px);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;

    color: white;
    font-size: 12px;
    z-index: 5;
}

.control {
    width: 80%;
    margin-left: 2px;
    margin: 5px auto;
}

.bone-control {
    display: block;
    margin: 10px 2px;
}

.bone-control p {
    margin-bottom: 0;
    color: rgb(0, 0, 0);
    font-weight: bold;
    background-image: linear-gradient(to top, #ffffff59, #ffffff);
}

.export {
    width: 90%;
    height: 40px;
    border-radius: 3px;
    background-color: #28abfb;
    border: 0px white solid;
    color: white;
    cursor: pointer;
    text-align: center;
    vertical-align: center;
    line-height: 38px;
    font-size: 18px;
    margin-bottom: 10px;
    margin: auto;
    transition: background-color 0.2s;
}

.export:hover {
    background-color: #4dbbff;
}

.MuiSlider-thumb{
    color: #28abfb;
}

.MuiSlider-rail{
    color: #28abfb;
}

.MuiSlider-track{
    color: #248fd1;
}

