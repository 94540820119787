.sectionTitle {
  text-transform: uppercase;
  background-color: var(--bgColor);
  margin-bottom: 0px;
}

.card {
  display: flex;
  flex-wrap: wrap;
  
}

.credArea {
 background: var(--componentBG);
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  border: 10px solid var(--componentBG);
  position: relative;
  align-items: center;


}
.card, .cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; /* Align items for uniform card height */
  
}

.overflow {
  overflow: visible !important;
}