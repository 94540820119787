/* Container for the item card */
.factoryItemContainer {
  position: relative;
  width: 120px;
  height: 120px;

  perspective: 1000px; /* 3D perspective */
  padding: 0;
  filter: saturate(1.3) contrast(1);
}

/* Item card styling */
.factoryItemCard {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #444;
  overflow: hidden;
  display: block;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  /* 3D effect styles */
  transform-style: preserve-3d;
  opacity: 0.8;
  background: radial-gradient(circle, #464646 25%, #202020 100%); 
  filter: brightness(1) saturate(1.5) contrast(1.1);
  will-change: transform, filter;
}

/* Thumbnail wrapper */
.factThumbnailWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.factThumbnailWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
}

/* Thumbnail images */
.factThumbnailImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: brightness(1.1) contrast(1);
}

.factShadowImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: blur(0) brightness(0);
  opacity: 0;
  transform: translateY(0);
  transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
}

/* Hover effects */
.factoryItemCard:hover {
  transform: scale(1.02) rotateY(15deg);
  filter: brightness(1.3) saturate(1.5) contrast(1.1);
  border-color: #fff;
}

.factoryItemCard:hover .factThumbnailImage {
  transform: translateY(-4px);
}

.factoryItemCard:hover .factShadowImage {
  filter: blur(6px) brightness(0);
  transform: translateY(10px);
  opacity: 0.8;
}

/* Overlay elements */
.factoryItemCard .factoryItemFeature,
.factoryItemCard .itemTitle {
  position: absolute;
  z-index: 3;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  filter: saturate(1.5) contrast(1.1);
}

.factoryItemCard .factoryItemFeature {
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: var(--secondary);
  background-color: #303030;
  border-radius: 5px;
  padding: 2px 5px;
}

.factoryItemCard .itemTitle {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.842);
}

/* Holographic effect pseudo-elements */
.factoryItemCard::before,
.factoryItemCard::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  pointer-events: none;
  transition: opacity 0.1s ease, filter 0.1s ease;
  background-repeat: no-repeat;
}

.factoryItemCard::before {
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    rgba(255, 255, 255, 0.41) 25%,
    transparent 47%,
    transparent 53%,
    rgba(255, 255, 255, 0.65) 75%,
    transparent 100%
  );
  background-position: var(--posX, 50%) var(--posY, 50%);
  background-size: 300% 300%;
  filter: brightness(0.5) contrast(1);
  border: 1px solid var(--primaryButtonBorder);
  z-index: 4;
}

.factoryItemCard::after {
  background-position: var(--posX, 50%) var(--posY, 50%);
  background-size: 160%;
  background-blend-mode: color-dodge;
  mix-blend-mode: color-dodge;
  filter: brightness(1) contrast(1);
  opacity: 0.3;
  z-index: 4;
}

.factoryItemCard:hover::before {
  opacity: 0.88;
  filter: brightness(1) contrast(1.33);
}

.factoryItemCard:hover::after {
  opacity: 1;
}


