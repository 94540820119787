header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;
  z-index: 1000 !important;
  background: var(--primary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.menu {
  font-size: 1.8rem;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.menu.active {
  margin-left: 0;
  transition: 1s;
}


.headerMenu {
  width: 100%;
  height: inherit;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  transition: 0.8s all;
  overflow: hidden;
  -webkit-transition: 0.8s all;

}
.headerMenu .headerMenuScroll {
  display: flex;
  max-width: 300px;
  
}

.headerMenu .hnav {
  list-style: none; /* Removes list bullets */
  display: flex; /* Makes items align side by side */
  gap: 5px; /* Adds a 5px gap between items */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  justify-content: flex-start; /* Align items to the left */
  
}

.headerMenu .hnav li {
  width: auto; /* Ensures items adapt to their content */
  height: auto;
}

.headerMenu .hnav li a {
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #00000000 ;
  transition: 0.3s all;
  text-shadow: rgb(0, 0, 0) 0px 2px 3px;
}

.factoryButton {
  background-image: linear-gradient(135deg, #ffb101 25%, #000000 25%, #000000 50%, #ffb101 50%, #ffb101 75%, #000000 75%, #000000 100%);
  background-size: 50.91px 50.91px;
  border: 1px solid #ffb101 !important;
}

.factoryButton:hover {
  border: 1px solid var(--secondary) !important;
}

.factoryButton.active {
  background-image: linear-gradient(135deg, #ffb101 25%, #000000 25%, #000000 50%, #ffb101 50%, #ffb101 75%, #000000 75%, #000000 100%) !important;
  background-size: 50.91px 50.91px !important;
  border: 1px solid var(--secondary) !important;
}


.headerMenu .hnav li a.active {
  border: 1px solid var(--secondary);
  background: var(--primaryButton);
}

.headerMenu .hnav li a:hover {
  border: 1px solid var(--primaryButtonBorder);

}

.userItems {
  display: flex;
  align-items: center;
  gap: 5px; /* Consistent spacing between items */
  justify-content: flex-end; /* Align all items to the right */
  width: auto;
  background-color: var(--primaryButton);
  padding: 10px;
  border-radius: 5px;
}



.avatar {
  height: 20px;
  display: flex;
  align-items: center;
}

.avatar img {
  width: 20px;
  height: 20px;
  border-radius: 10%;
  position: relative;

}

.user {
  display: flex;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  position: relative;


}

.user span {
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerCredContainer {
  display: flex;
  align-items: center;
  gap: 5px; /* Consistent spacing between icon and text */
  margin-left: 10px;
}

.credIcon img {
  width: 20px;
  height: 20px;
  position: relative;
  bottom: 4px;
}
.credIcon {
  width: 20px;
  height: 20px;
}

.credAmount {
  font-size: 14px;
  color: var(--secondary);
  line-height:normal;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;

}

.btn-collect {
  text-decoration: none;
  color: #2c2c2c;
  font-size: 1.2em;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  border-radius: 10px;
  padding: 10px 20px;
  transition: 0.3s;
  width: 100%;
  border: 1px solid var(--primaryButtonBorder);
  background: var(--primaryButton);
  height: 50px;
}

.btn-collect:hover {
  background: var(--secondary);
  color: #2c2c2c;
}

.btn-collect:before {
  position: absolute;
  content: '';
  background-color: #fff;
}

@media (max-width: 1275px) {
  .menu {
    font-size: 1.8rem;
    color: #ffffff;
    transition: 1s;
  }

  .menu.active {
    margin-left: 0;
  }
}

.headerLogo {
  position: relative;
  text-decoration: none;
  display: flex; /* Changed from inline-flex to flex */
  align-items: center;
  transform-origin: center; /* Ensure scaling is centered */
  justify-content: center;
  width: 125px;
  max-height: 45px !important;
}

.headerLogo img {
  width: 125px;
  max-height: 45px !important;
}