/* --------------------------------------------------------
Style Sheet for the Category.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- category styling on the left side with the circles and the svg
----------------------------------------------------------*/

.panel {
    background-color: rgba(255, 255, 255, 0);
    width: 25%;
    height: 100%;
}

.rightbar {

    width: 400px;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary);
    position: absolute;
    /*overflow-y: hidden;
    overflow-x: hidden;*/
    z-index: 1;
    top: 0px;
    right: 0px;
    /* Change this to 'right' */
    transition: transform 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
    border-radius: 10px;
}

.right-side {
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: transform 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
}

.sidebar-toggle-button-right {
    position: inherit;
    width: 25px;
    height: 45px;
    right: 100%;
    top: 50vh;
    background-color: var(--primary) !important;
    color: #28abfb;
    padding: 10px;
    z-index: 1;
    transition: right 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
    border-radius: 10px 0px 0px 10px;
    /* Reverse the border radius */
    padding-bottom: 15px;
    padding-right: 15px;
    /* Change this to 'padding-left' */
    border: 0px solid #fff !important;
}

.sidebar-toggle-button-right-hidden {
    position: fixed;
    width: 25px;
    height: 45px;
    left: -25px;
    /* Change this to 'right' */
    top: 50vh;
    background-color: var(--primary) !important;
    color: #28abfb;
    padding: 10px;
    z-index: 1;
    transition: right 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
    border-radius: 10px 0px 0px 10px;
    /* Reverse the border radius */
    padding-bottom: 15px;
    padding-right: 15px;
    /* Change this to 'padding-left' */
}

.clipglow {
    clip-path: inset(-50px 0px -50px -50px);
}

.all {
    overflow-x: hidden;
}

.leftbar {
    width: 167px;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    background-color: #1f2239;
    position: absolute;
    z-index: 1;
    top: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
    transition: transform 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
}

.hiddenrt {
    transform: translateX(100%);
    right: 0px;
}

.hiddenlt {
    transform: translateX(-100%);
    left: 0px;
}

.sidebar-toggle-button {
    position: fixed;
    width: 20px;
    height: 45px;
    left: 167px;
    top: 50vh;
    background-color: #1f2239;
    color: #28abfb;
    padding: 10px;
    z-index: 1;
    transition: left 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
    border-radius: 0px 10px 10px 0px;
    padding-bottom: 15px;
    padding-right: 15px;
}

.sidebar-toggle-button-hidden {
    position: fixed;
    width: 20px;
    height: 45px;
    left: 0px;
    top: 50vh;
    background-color: #1f2239;
    color: #28abfb;
    padding: 10px;
    z-index: 1;
    transition: left 0.5s cubic-bezier(.24, 1.73, .15, .2);
    /* Apply a bounce effect */
    border-radius: 0px 10px 10px 0px;
    padding-bottom: 15px;
    padding-right: 15px;
}





.left-side {
    width: 20%;
    max-width: 60px;
    height: 100%;
    display: table;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.category-name {
    text-transform: uppercase;
    color: #ffffff;

    letter-spacing: 0.00025em;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    position: relative;
    right: 0px;
    top: 13px;
    text-align: center;
    font-size: 15px;


}

.selected_category-name {
    text-transform: uppercase;
    color: #ffffff;

    letter-spacing: 0.00025em;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    position: relative;
    right: 0px;
    top: 13px;
    text-align: center;
    font-size: 15px;
}

.category-nameold {
    text-transform: uppercase;
    color: #ffffff;

    letter-spacing: 0.00025em;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    position: relative;
    right: 10px;
    top: 13px;
    text-align: right;
    font-size: 15px;


}

.selected_category-nameold {
    text-transform: uppercase;
    color: #ffffff;

    letter-spacing: 0.00025em;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    position: relative;
    right: 10px;
    top: 13px;
    text-align: right;
    font-size: 15px;
}

.category {
    z-index: 7;
    width: 140px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #383d63;
    border-radius: 15px;
    margin-left: 5px;
    margin-right: 5px;
    transition: background-color 0.2s;
    transform: translateX(-30px);
    cursor: pointer;
    position: relative;
    left: 30px;
    height: 45px;
    top: 85px;
    border-style: solid;
    border-width: 0px;
    border-color: #494f79;
    box-sizing: border-box;

}

.category:hover {
    background: #656a97;
    border-color: #7b80aa;
    /*background-color: rgb(0, 158, 84);*/
    /*box-shadow: 0 0 20px #1e253f83;*/
    /*box-shadow: inset 9px 0px 5px 1px #7d7da083;*/
}

.selected-category:before {
    content: "";
    position: absolute;
    border-radius: inherit;
    border-top: 0px solid #000000;
    border-right: 0px solid #000000;
    border-bottom: 0px solid #000000;
    inset: 0px;
}

.selected-category {
    background: #28abfb;
    border-color: #a4dcff;
    box-shadow: 
    0px 4px 6px rgba(0, 0, 0, 0.4),
    0 0 10px var(--secondary);
}

.category img {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    pointer-events: inherit;
    width: 75%;
    max-height: 95%;
    height: 80%;

}