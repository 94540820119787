.loading-container {
  top: 120%;
  position: absolute;

  left: 50%;
  transform: translate(-50%);
}

.loading {
  padding-top: 50px;
}

.pageloader {
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.pageloader img {
  width: 400px;
}

.pageloadscreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* Adjust based on your actual layout needs */
  background-color: var(--primary);
  position: relative;
  /*background-image: linear-gradient(to right, #030203, #0d101b);*/
z-index: 999;
border-radius: 10px;
  justify-content: center;
  text-align: center;
}

.textbackload {
  border-radius: 5px;
  margin-bottom: 0px;
  width: 95%;
  z-index: 15;
  height: 100%;

}

.loader-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  position: relative;
  padding-top: 0px;
  margin: 5px auto;
  bottom: 40px;
  padding: 5px;
  top: 10%;
}

.loaderCentering {
  position: relative;
  /* Ensures absolute positioning of children is relative to this container */
  width: 575px;
  /* Width of the canvas */
  height: 175px;
  /* Height of the canvas */
  margin: auto;
  /* Center the loaderCentering container if it's within a larger container */
  display: flex;
  justify-content: center;
}

.three-canvas {
  width: inherit;
  height: 190px;
  position: absolute;
  top: 0;
  left: -200px;
  z-index: 1;
  left: -246px;

}

.loadercontainer {
  position: absolute;
  top: 0;
  right: 35px;
  /* Align to the far right edge */
  z-index: 2;
  /* Ensure loaderContainer is above the canvas */
  width: auto;
  /* Adjust according to content size or specify a width */
  height: 100%;
  /* Match height of the loaderCentering container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center content vertically */
  align-items: center;
  /* Center content horizontally */
}

.App-logo-load {
  width: 400px;
  justify-content: center;
  pointer-events: none;
}

.pageloadscreen {
  position: relative;
  width: 100%;
  /* Adjust based on your needs */
  height: 100%;
  /* Adjust based on your needs */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.loading-text-container {
  text-align: end;
  width: 100%;
  bottom: -92px;
  position: relative;
  z-index: 2;
  right: 30px;
}

.loading-text {
  font-size: xx-large;

}

.neon {
  text-shadow: 0 0 5px var(--secondary), 0 0 15px var(--secondary), 0 0 20px var(--secondary), 0 0 40px var(--secondary), 0 0 60px var(--secondary), 0 0 10px var(--secondary), 0 0 98px var(--secondary);
  color: #fff;

}