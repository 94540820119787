.popup_welcome {
    height: 475px;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--primary);
    border-radius: 10px;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4); /* Add a drop shadow */
}

.space {
    padding: 5px;
}
.pop_background {
    order: 1;
}

.welcome-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0%;
}

.pop-img_welcome {
    border-radius: 10px 0 0 10px;
    width: 300px;
    height: 400px;
    object-fit: scale-down;
    position: fixed;
    bottom: 245px;
  }

.welcome_abs {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
      
}

.welcome-title {
  
    letter-spacing: 0.00025em;
    text-shadow: rgba(0, 0, 0, 1) 0px 4px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 80px;
    position: absolute;
    font-size: xx-large;
    text-transform: uppercase;
    margin: 0px auto;
    top: -95px;
    text-align: center;
  }
  
  .date-text {
    display: flex;
  
    width: 100%;
    height: auto;
    position: relative;
    left: 25px;
    bottom: -10px;
    z-index: 19;
  }

.welcome-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: auto;
    position: relative;
    margin: 5px auto;
    text-align: center;
  }

  .textback {
    background-color: #0000008f;
    border-radius: 5px;
    margin-bottom: 30px;
    width: 95%;
    z-index: 15;
    height: auto;
  }

  .spacerwel {
    height: auto;
    position: relative;
    display: flex;
  }

.welcome_message {
    font-family: 'Roboto', sans-serif;
    font-size: large;
    height: 240px;
    text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 50%;
  }

  .custom-btn {
    width: 250px;
    height: 45px;
    color: #fff;
    border-radius: 8px;
  
    letter-spacing: 0.00025em;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    font-weight: 500;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    bottom: 0%;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    line-height: 3px;
    margin-bottom: 5px;
  }


  .btn-09 {
    border: none;
    background: linear-gradient(0deg, #4a2dc5 0%, #7d65e6 100%);
    overflow: hidden;
    font-size: large;
    bottom: 20px;
    left: 0px;
    border-top: 1px solid #927eeb;
    border-left: 1px solid #927eeb; 
}
  .btn-09:hover {
      text-decoration: none;
      color: #fff;
  }
  .btn-09:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      
  }

  .btn-09:hover{
    background: linear-gradient(0deg, #4a2dc5 0%, #a490ff 100%);
  }



  
  .btn-10 {
    border: none;
    background: linear-gradient(0deg, #df9800 0%, #ffcc5e 100%);
    color: #fff;
    overflow: hidden;
    font-size: large;
    bottom: 20px;
    left: 0px;
    border-top: 1px solid #ffe19f;
    border-left: 1px solid #ffe19f; 
    
}
  .btn-10:hover {
      text-decoration: none;
      color: #fff;
  }
  .btn-10:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      animation: shiny-btn1 3s ease-in-out infinite;
  }

  .btn-10:hover{
    background: linear-gradient(0deg, #fbb828 0%, #ffefcd 100%);
  }

.btn-11 {
    border: none;
    background: linear-gradient(0deg, #0870b1 0%, #4bbaff 100%);
    color: #fff;
    overflow: hidden;
    font-size: large;
    bottom: 20px;
    left: 0px;
    border-top: 1px solid #a1dbff;
    border-left: 1px solid #a1dbff; 
    
}
  
  .btn-11:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      
  }

  .btn-11:hover{
    background: linear-gradient(0deg, #4dbbff 0%, #c4e8ff 100%);
  }

 


  
 
  
  
  @-webkit-keyframes shiny-btn1 {
      0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
      80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
      81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
      100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
  }