/* src/css/ColorWheel.css */

.color-wheel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 230px;
    height: 230px;
    display: flex;
    justify-content: flex-start; /* Align horizontally to the left */
    align-items: flex-start;     /* Align vertically to the top */
    padding-top: 80px;
    padding-left: 10px;               /* Space from the top and left edges */
    z-index: 1000;
  }
  
  .color-wheel-container {
    position: relative;
    background-color: var(--primary);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    animation: slideIn 0.3s ease;          /* Optional: Slide-in animation */
    /* Responsive width */
    width: 90%;
    max-width: 300px; /* Adjust as needed for top-left placement */
  }
  
  .close-buttoncw {

    padding: 8px 12px;
    background-color: --var(--primaryButton);
    border: 1px solid --var(--primaryButtonBorder);
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.2s;
    margin-bottom: 10px;
    right: 0px;
    position: absolute;
    margin-right: 20px;
  }
  .close-buttoncw:hover {
    background-color: #e91616;
  }
  
  .close-buttoncw:active {
    background-color: #fff;
  }
  
  /* Slide-in animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (min-width: 600px) {
    .color-wheel-container {
      width: 300px; /* Fixed width on larger screens */
    }
  }
  
  /* Position the Random Color button below the color picker */
.random-color-button {

  padding: 8px 12px;
  background-color: --var(--primaryButton);
  border: 1px solid --var(--primaryButtonBorder);
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.2s;
  margin-bottom: 10px;
}

.random-color-button:hover {
  background-color: --var(--secondary);
}

.random-color-button:active {
  background-color: #fff;
}
