/* --------------------------------------------------------
Style Sheet for the Selector.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- Selector
- Side Indicator
- Elements
- Corner vignette
----------------------------------------------------------*/

/* Box div for Selector */
/* -------------------- */

body {
    overflow-x: hidden;
}

.box {
    position: absolute;
    width: 100%;
    height: inherit;
    border-radius: 2px;
    max-width: 570px;
    min-width: 150px;
    right: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adds space between child divs */
    padding: 10px; /* Adds padding inside the box */
    padding-top: 60px;
  }

.selector {
    width: calc(100% - 5px);
    z-index: 2;
    /* Lower than .search-container to keep it below */
    overflow-x: hidden;
    top: 25px;
    /* Push down below .search-container, adjust as needed */
    height: auto;
    position: relative !important;
}

.selector-no-padding {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr 1fr 1fr;
    gap: 5px;
    overflow-x: hidden;
    background-color: transparent;
    height: inherit;
}

.selector-full {
    height: auto;
    overflow-x: hidden;
    display: flex;
}

/* Media queries for the number of columns in the selector */
@media (min-width: 930px) {
    .selector-no-padding {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: 1100px) {
    .selector-no-padding {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* Side indicator style */
/* -------------------- */

.switch {
    height: 30px;
    width: calc(100% - 20px);
    max-width: 520px;
    border-radius: 3px;

    display: flex;
    margin: 0 auto; /* Center the switch within the box */
  }

.side {
    width: 50%;
    height: 30px;
    text-align: center;
    vertical-align: center;
    line-height: 28px;
    font-size: 18px;
    color: white;
    background: var(--primary);
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: bold;
    font-size: medium;
    text-transform: uppercase;
    transition: background-color 0.2s;
    margin: 2px;
    border: 1px solid #00000000;
    border-radius: 5px;
}

.side:hover {

    border: 1px solid var(--primaryButtonBorder);
}

.side-selected {
    background: var(--primaryButton);
    color: rgb(255, 255, 255);
    border: 1px solid var(--secondary);

}

.R {

}

.L {

}

/* Element in the selector styling */
/* ------------------------------- */
.items {
    width: calc(100% - 70px);
    margin: auto;
    position: relative;
    right: 5%;
}

.elContainer {
    background: var(--primary);
    width: 100%;
    max-width: 120px;
    height: fit-content;
    display: grid;
    border: 1px solid var(--primaryButtonBorder);
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
    row-gap: 10px;
}

.el {
    width: inherit;
    height: inherit;
    align-items: center;
    transition: background-color 0.2s;
    position: relative;
    border-radius: 8px;
    
    overflow: hidden;
    padding: 5px 5px 5px 5px;
}

.el:hover {
    background: var(--secondary);
    box-shadow:
        0px 4px 6px rgba(0, 0, 0, 0.4),
        0 0 5px var(--secondary);
}

.el.premium2 {
    /*background-image: linear-gradient(to bottom, #4d006b, #38004e);*/
    background: #2d3150;
    cursor: pointer;
}

.el.premium2:hover {
    background: #28abfb;
    border: 0px solid #ff5100;
}

.img {
    padding: 2px;
}

.img img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    left: 0;
    border-radius: 5px;
}

.el-name {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    vertical-align: middle;
    line-height: 17px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;
    top: 0px;
}

/* Small top corner vignettes for links and premium information */
/* ------------------------------------------------------------ */

.premium {
    bottom: 60px;
    right: 0;
    width: 55px;
    height: 20px;
    background-color: rgb(221, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 10px 0 0px 10px;
    font-size: small;
    font-weight: bold;
}

.premium:hover {
    /*background-color: rgba(0, 255, 21, 0.8);*/
}

.link {
    bottom: 40px;
    right: 0;
    width: 40px;
    height: 20px;
    border-radius: 10px 0px 0 10px;
    background-color: rgb(0, 110, 255);
    color: rgb(255, 255, 255);
    font-size: small;
    font-weight: bold;
}

.link:hover {
    background-color: rgb(52, 140, 255);
}

.icon {
    font-size: 11px;
}

.big-icon {
    /* Premium icon is bigger */
    font-size: 13px;
}

.license {
    bottom: 20px;
    right: 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
    border-radius: 10px 0 0px 10px;
    background-color: #ea3f22;
    color: rgb(255, 255, 255);
    font-size: small;
    font-weight: bold;
}

.license:hover {
    background-color: #ff6044;
}

.licenseIcon {
    font-size: 15px;
    left: 10px;
}

.description {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(90, 0, 94);
    color: white;
    padding: 5px;
    font-size: 14px;
}

.skyimg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: relative;
    /* Added position: relative */
}

.skyimg img {
    display: block;
    width: 100%;
    position: relative;
    /* Added position: relative */
    min-height: 100px;
    border-radius: 5px;

}

.skyimg img:hover {
    filter: brightness(110%);
}

.skyimg img:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}

.AI {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 25px;
    height: 20px;
    font-size: 20px;
    border-radius: 10px 0 0px 10px;
    background-color: #3ff78d;
    color: rgb(255, 255, 255);
    font-size: small;
    font-weight: bold;
}

.AI:hover {
    /* background-color: rgba(0, 255, 21, 0.8); */
}

.skyname {
    position: relative;
    bottom: -5px;
    width: 100%;
    height: fit-content;
    color: rgb(255, 255, 255);
    text-align: center;
    vertical-align: middle;
    line-height: 17px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 12px;
    font-weight: 100;
    text-transform: uppercase;
    padding: 0px 0 10px 0;
    left: 0;
}