
.sprite-button {
    position: absolute;
    z-index: 999;
    cursor: pointer;
    pointer-events: auto;
  }
  
  .sprite-button-hover {
    filter: brightness(300%);
    border: 2px solid rgba(255, 94, 0, 0.75); /* Orange glow */
    box-shadow: 0 0 8px 4px rgba(255, 94, 0, 0.6); /* Soft outer glow */
  }

  .FPS {
    left: 700px;
  }