/* Swiper Container */
.showcase-swiper {
  width: 1026px;
  height: 350px;
  background-color: var(--bannerBG);
  box-shadow: 0px 4px 6px -3px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

/* Individual Slide */
.showcase-slide {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: var(--bannerBG);
}

/* Left Side: Image */
.showcase-image {
  width: 650px;
  height: 100%;

}

.showcase-image  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 10px 10px 0;
}

/* Right Side: Game Details */
.showcase-details {
  width: 640px;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--componentBG); /* Dark background for the text */
  color: white;
  border-radius: 10px 0 0 10px;
  top: 25px;
  position: relative;
  box-shadow: inset -10px 0 20px rgba(0, 0, 0, 0.5);
}

.showcase-details h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 5px;
  width: 95%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}

.showcase-details p {
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1; /* Ensures description grows to fill space */
  
}

.showcaseDescription {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  height: 140px;
  margin-top: 5px;
  width: 100%;
  text-align: center;
  overflow: scroll;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  display: flex;
}

.showcasePriceContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: max-content;
  gap: 5px;
  height: 30px;
  align-items: flex-start;
  justify-content:flex-start; /* Align items to the right */
  left: 5px;
  background-color: #1b1b1b;
  margin-bottom: 5px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
}

.showcasePriceIcon {
  aspect-ratio: 1/1;
  z-index: 1;
  position: relative;
  width: 24px !important; 
  height: auto;
  bottom: 0px !important;
  line-height: 0px;
}

.showcasePrice {
  font-size: 15px !important;
  line-height: 0px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff; /* Custom color for the price */
  border-radius: 5px;
  display: flex;
  align-items: center; /* Vertically centers the text */
  justify-content: center; /* Optionally centers text horizontally */
  position: relative;
  margin-top: 10px;
}


/* Button Styling */
.orderBtn {
  align-self: center;
  font-size: 20px;
  padding: 5px 30px 5px 30px;
  width: fit-content;
  background: rgb(68,150,40);
  background: linear-gradient(0deg, rgba(68,150,40,1) 0%, rgba(97,209,59,1) 100%);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  position: absolute;
  display: flex;
  margin: 10px;
  bottom: 68px;
}

.orderBtn:hover {
  background: rgb(68,150,40);
  background: linear-gradient(0deg, rgb(83, 184, 49) 0%, rgb(112, 245, 68) 100%);
}

/* Swiper Navigation Buttons */
.swiper-button-next,
.swiper-button-prev {
  color: white; /* White navigation arrows */
  transition: color 0.3s;
  top: 71%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 25px;
  border-radius: 5px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--secondary);
}


.inclusionChart {
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  justify-content: center; /* Centers items horizontally within each row */
  align-items: center;     /* (Optional) Centers items vertically within each row */
  background-color: #242424;
  padding: 5px;
bottom: 0px;
  gap: 5px;                /* (Optional) Adds consistent spacing between items */
}


.partID {
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 4px;
  margin: 3px;
  display: inline-block;
  border: 1px solid;
  transition: all 0.3s ease;
  line-height: 14px;
}

.partID.active {
  color: var(--secondary);
  border-color: var(--secondary);
}

.partID.inactive {
  color: grey;
  border-color: grey;
}