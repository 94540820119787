/* --------------------------------------------------------
Style Sheet for the Popup.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- styles for popup
----------------------------------------------------------*/

.screen {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 13;
    
}

.screenpop {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.63);
    z-index: 10;
    backdrop-filter: blur(2px);
    position: fixed; /* Using 'fixed' instead of 'absolute' */
    top: 0;
    left: 0;
}


.popup {
    box-sizing: content-box;
    height: 500px;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #383d63;
    border-radius: 10px;
    color: white;
    position: absolute;
    display: inline-block;   
    border-top: 1px solid #4b5292;
    border-left: 1px solid #4b5292; 
    border-right: 1px solid #262a49;
    border-bottom: 1px solid #262a49;  
    
}

.pop-img {
    border-radius: 10px 10px 0 0;
    width: 475px;
    height: 250px;

}

.welcome_container {
    width: 465px;
    height: 240px;
    position: relative;
    bottom: 100px;
}

.welcome_title {
    font-family: 'Roboto', sans-serif;
    font-size: xx-large;
    font-weight: bold;
    min-width: 465px;
    width: 465px;
    height: 45px;
    text-align: center;
    bottom: 150px;
    left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;

}

.welcome_message {
    font-family: 'Roboto', sans-serif;
    font-size: medium;
    width: 450px;
    height: 140px;
    text-align: center;
    bottom: -10px;
    left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
}

.welcome_message:nth-child(2) {
    bottom: 55px;
  }
  
  /* target the third .welcome_message div */
  .welcome_message:nth-child(3) {
    
  }

.message {
    width: 100%;
    height:fit-content;
    margin: 5px auto;
}




.pop-background {
    position: absolute;
    
}

.img-container {
    text-align: left;
    
    width: 50%;
    height: 25%;
    margin-left: 25%;
    padding-top: 100px;
    padding-bottom: 100px;
    animation: scale 0s ease-in-out 0s infinite normal forwards;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;
}

.info-logo {
    width: 200px;
    height: fit-content;
    pointer-events: none;
    left: -115px;
    bottom: 22px;
    position: absolute;
}

.cross {
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    color: var(--primaryButton) !important;
    width: fit-content;
}

.cross:hover {
    color: var(--secondary);
    font-size: 30px;
}


