/* --------------------------------------------------------
Style Sheet for the all the component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- main classes that are used almost everywhere
----------------------------------------------------------*/
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}


.App {}

.App-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;

}



.App-header {
    background: var(--primary);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #ffffff;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.abs {
    position: absolute;
    column-gap: 10px;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.white {
    color: white;
}

.full-screen-message-title {
    text-align: center;
    width: 90%;
    padding-top: 20px;

    font-size: x-large;
    text-transform: uppercase;
    top: 10px;
    position: relative;
}

.full-screen-message {
    text-align: center;
    width: 90%;
    padding: 20px 0 50px 0px;
    font-size: large;
}

.smart-phone img {
    width: 100px;
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dark-screen {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 14;
}

textarea,
select,
input,
button {
    outline: none;
}

.webloader {
    position: absolute;
    display: flex;
    justify-content: center;
}

.webloaderimg {
    position: relative;
    top: 45%;
    display: flex;
    justify-content: center;
    width: 400px;
    height: fit-content;

}

.pagescreen {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 11;
    display: flex;
    justify-content: center;
}

.partloaderimg {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
}

.partscreen {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 12;
    display: flex;
    justify-content: center;
}