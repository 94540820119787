/* discordButton.css */
.discordCustom {
    color: white;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #5865f2;
    text-decoration: none;
    white-space: nowrap;
    overflow-x: hidden;
    width: 93%;
    left: 50%;
  position: relative;
  transform: translateX(-50%);
  transition: 0.3s;
}

/* Hover effect for the entire button */
.discordCustom:hover {
    background-color: #7d88ff;
    box-shadow: 
    0px 4px 6px rgba(0, 0, 0, 0.4),
    0 0 10px #7d88ff;
    
}

/* Styling for the SVG icon inside the button */
.discordCustom .dcicon {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    display: block;
    position: relative;
    min-width: 35px;
    top: 2px;
    right: 3px;
}

.discordCustom svg {
    fill: white;  /* This will apply to all SVG elements within .discordCustom */
}

.centerfix {
    display: flex;
    width: 100%;
    list-style: none;
    gap: 15px;
    padding-bottom: 20px;
    position: relative;
    padding-top: 10px;
    transition: 0.3s;
    padding-left: 0px;
}

.centermaybe {
    width: inherit;
}
