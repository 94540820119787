code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas {
    z-index: -2;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
}