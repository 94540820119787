/* --------------------------------------------------------
Style Sheet for the Loader.js component

author: 
e-mail: 
github: https://github.com/

CONTENTS:
- Animations for the loaders (rotating  logo)
----------------------------------------------------------*/

.loader {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #ff0000;
    ;
}

.loader img {
    width: 400px;
    
}


