.filters {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 10px;
  padding: 0;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.filters li {
  color: #ffffff;
  padding: 10px 22px;
  transition: 0.3s;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
  background: var(--primary);
}

.filters li:hover,
.filters li.active {
  background: var(--secondary);
  box-shadow: 
  0px 4px 6px rgba(0, 0, 0, 0.4),
  0 0 10px var(--secondary);
}

.search {
  color: #ffffff;
  display: inline-flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 8px 0px;
  margin-bottom: 20px;
}

.search input {
  border-radius: 8px;
  background: transparent;
  font-size: 1.2rem;
  width: 100%;
  border-color: #ffffff;
  text-align: center;
}

.search input:focus {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: #1b1b1b83;
  box-shadow: 
  0px 4px 6px rgba(0, 0, 0, 0.4),
  0 0 10px var(--secondary) !important;
}

.search input::placeholder {
  color: #ffffff;
}
