/* src/css/ColorSelector.css */

.color-selector {
  display: flex;
  flex-direction: row; /* Side-by-side layout */
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--primary);
  width: 100%;
  z-index: 1;
  position: relative;
  gap: 20px; /* Space between sections */
  margin-top: 10px;
}

.color-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Equal width */
  background-color: #313131;
  padding: 5px;
  border-radius: 15px;
}

.custom-color-input {
  display: flex;
  flex-direction: column; /* Stack button above input */
  align-items: center;
  gap: 5px; /* Space between button and input */
  margin-bottom: 10px; /* Space below */
}

.custom-color-input button {
  margin-bottom: 5px; /* Ensure button is above input */
  padding: 5px 10px;
  border: 1px solid --var(--primaryButtonBorder);
  background-color: --var(--primaryButton);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-color-input button:hover {
  background-color: #e0e0e0;
}

.color-presets {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-template-rows: repeat(2, 1fr); /* 2 rows */
  gap: 5px; /* Space between buttons */
  width: 100%;
  justify-items: center; /* Center within grid cells */
}

.color-button {
  padding: 0;
  width: 30px; /* Increased size */
  height: 30px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  cursor: pointer;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.233),
      rgba(255, 255, 255, 0.1)
    ),
    var(--color);
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.26),
    0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.color-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.color-input-width {
  width: 100px; /* Adjusted width */
  border-radius: 10px !important;
  height: 35px; /* Increased height */
  padding: 5px 10px !important; /* Added padding */
  text-align: center;
  overflow: hidden;
  border: 1px solid #ccc; /* Default border */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.color-input-width::placeholder {
  color: #999999;
  opacity: 1; /* Ensure full opacity */
}

/* Style for invalid input */
.color-input-width.invalid {
  border-color: red;
}

/* Optional: Focus styles for accessibility */
.color-input-width:focus {
  outline: none;
  border-color: #6666ff;
  box-shadow: 0 0 5px rgba(102, 102, 255, 0.5);
}

/* Optional: Style color buttons when focused */
.color-button:focus {
  outline: 2px solid #000000;
}

/* Responsive Design */
@media (max-width: 600px) {
  .color-selector {
    flex-direction: column;
    gap: 10px;
  }
}

.colorWheelTitle {
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  top: -5px;
  font-size: 1.2rem;
}