section.factory .sectionTitle {
    text-transform: uppercase;
    background-color: var(--bgColor);
  }
  
  section.factory {
    border-radius: 10px;
    width: 100% !important;
    height: 100% !important;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .factViewMore {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    transition: 0.3s;
  }
  
  .factViewMore:hover {
    color: var(--secondary);
  }
  
  .card {
    display: flex;
    flex-wrap: wrap;
  }
  
