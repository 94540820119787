@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Titan+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Russo+One&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #111111;
  --primaryButton: #313131;
  --primaryButtonBorder: #444444;
  --secondary: #00fcef;
  --bgColor: linear-gradient(0deg, #40ebff, #337db9);
  --homeColor: #383d63;
  --liked: #ff0000;
  --bag: #3cff00;
  --stars: #ffd000;
  --coin: #cbf647;
  --shop: #1b1b1b;
  --shopBG: rgba(#ffd0009a);
  --shopHighlight: #ff00ea;
  --test: #007FFF;
  --bannerBG: #1b1b1b;
  --componentBG: #303030;
  --color1: rgb(0, 231, 255);
  --color2: rgb(255, 0, 231);
  --cardColor: #e7e7e7;
}

h1,
h2,
h4,
p {
  color: #ffffff;
  font-weight: lighter;
  font-size: x-large;
}

body {
  position: relative;
  font-family: 'Concert One', sans-serif;
  font-weight: lighter;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
}

a {
  text-decoration: none; /* Removes underline from links */
}

