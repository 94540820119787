/* Define root variables */
:root {
  --color1: #ec9bb6;
  --color2: #ccac6f;
  --color3: #69e4a5;
  --color4: #8ec5d6;
  --color5: #b98cce;
}

/* Container for the item card */
.itemContainer {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
  perspective: 1000px; /* Add perspective for 3D effect */
  padding: 0 !important;
}

.fadeHome {
  background: rgb(136,151,207);
  background: radial-gradient(circle, #404655 25%, #202020 100%); 
  filter: blur(7px);
  height: 200%;
  width: 200%;
  transform: translateX(-50%);
  left: 50%;
  top: -100px;
  z-index: -1;
  position: absolute;
  opacity: 0.2;
}
/* Item card styling */
.itemCard {
  position: relative;
  height: inherit;
  width: inherit;
  border-radius: 10px;
  border: 1px solid #494949;

  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  left: 50%;
  transform: translateX(-50%);
  display: block;
  transition: transform 0.0s ease, box-shadow 0.5s ease;
  will-change: transform, filter;
  cursor: pointer;
  /* 3D effect styles */
  transform-style: preserve-3d;
  
}

/* Image covers the entire card */
.itemCard img {
  position: absolute; /* Position absolutely within .itemCard */
  filter: saturate(1.5);
  height: 90%;
  width: 90%;
  object-fit: cover;
  object-position: center;

  z-index: 1; /* Ensure image is below overlay elements */
  left:50%;
  transform: translateX(-50%);
}

/* Overlay elements */
.itemCard .itemFeature,
.itemCard .itemTitle,
.itemCard .itemDescription,
.itemCard .itemPrice,
.itemCard .inventoryAction,
.itemCard .discount,
.itemCard .prevPrice,
.itemCard .itemSaleTag,
.itemCard .like {
  position: absolute;
  z-index: 2; /* Ensure overlays are above the image */
}

/* Adjust positions and styles of overlay elements */
.itemCard .itemFeature {
  top: 5px;
  right: 5px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: var(--secondary);
  background-color: #303030;
  border-radius: 5px;
  border: 1px solid var(--secondary);
  padding: 2px 5px;

}

/* ItemCardSCSS.scss */

.itemCard .itemTitle {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 0px);
  padding: 5px 5px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  color: #fff;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.842);
  white-space: nowrap;
}

.titleContainer {
  display: inline-block;
  padding-left: 0;
  transform: translateX(0%);
}

.itemCardBounce {
  animation-name: bounce;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(calc(-100% + 100%)); /* Adjust this value based on container width */
  }
  100% {
    transform: translateX(0%);
  }
}
.titleContainer.scroll {
  animation: scrollText var(--animation-duration) linear infinite;
  -webkit-animation: scrollText var(--animation-duration) linear infinite;
}

@-webkit-keyframes scrollText {
  /* Same keyframes as above */
}

.itemCard .itemTitleShadow {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 0px);
  padding: 5px 5px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  text-align: center;
  filter: blur(5px);
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
}
.priceContainer {
  position: absolute;
  bottom: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  align-items: flex-end;
  justify-content: flex-start; /* Align items to the right */
  padding-left: 5px;
}

.priceIcon {
  aspect-ratio: 1/1;
  z-index: 1;
  position: relative;
  height: 20px; 
  bottom: -1px;
}

.itemCard .itemPrice {
  color: #fff;
  font-size: 15px !important;
  position: relative;
  line-height: normal;
  font-family: Arial, Helvetica, sans-serif;
}

.itemCard .inventoryAction {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  color: #fff !important;
  background: linear-gradient(0deg, #df9800 0%, #ffcc5e 100%);
  width: fit-content;
  min-width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 1.2rem;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  padding: 0px 10px;
  z-index: 3; /* Above other overlays */
}

.itemCard .discount {
  top: 10px;
  left: 10px;
  background: #ff0000;
  color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 800;
  font-family: 'Archivo Narrow', sans-serif;
}

.itemCard .prevPrice {
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffd000;
  text-decoration: line-through;
  text-decoration-color: #ff0000;
  text-decoration-thickness: 1px;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 800;
  font-family: 'Archivo Narrow', sans-serif;
  color: #000;
}

.itemCard .itemSaleTag {
  top: 10px;
  right: 10px;
  background: #ffd000;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 800;
  font-family: 'Archivo Narrow', sans-serif;
  color: #000;
}

.itemCard .like {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background: #636caf;
  padding: 5px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

.itemCard .like.active {
  color: var(--liked);
}

.itemCard .itemDescription {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.7);
}

/* Holographic effect pseudo-elements */
.itemCard::before,
.itemCard::after {
  content: "";
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  transition: all 0.1s ease;
  pointer-events: none;
}

.itemCard::before {
  background-position: var(--posX, 50%) var(--posY, 50%);
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    #ffffff69 25%,
    transparent 47%,
    transparent 53%,
    #ffffff6c 75%,
    transparent 100%
  );

  z-index: 3; /* Above image, below overlays */
}

.itemCard::after {

  background-position: var(--posX, 50%) var(--posY, 50%);
  background-size: 160%;
  background-blend-mode: color-dodge;

  mix-blend-mode: color-dodge;
  opacity: 0.3;
  z-index: 3;
}

.itemCard:hover,
.itemCard.active {
  transform: translateX(-50%) scale(1.02) rotateY(15deg); /* Added rotateY for 3D effect */
}

.itemCard:hover::before,
.itemCard.active::before {
  
  opacity: 0.88;

  transition: none;
}

.itemCard:hover::after,
.itemCard.active::after {
  
  opacity: 1;
  transition: none;
}

.itemCard.animated {
  transition: none;
  animation: holoCard 12s ease 0s 1;
}

.itemCard.animated::before {
  transition: none;
  animation: holoGradient 12s ease 0s 1;
}

.itemCard.animated::after {
  transition: none;
  animation: holoSparkle 12s ease 0s 1;
}

/* Keyframe animations */
@keyframes holoSparkle {
  0%,
  100% {
    opacity: 0.75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }
  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(0.8) contrast(1.2);
  }
  13%,
  16% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(0.8);
  }
  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }
  55% {
    opacity: 0.33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes holoGradient {
  0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(0.5) contrast(1);
  }
  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
  13%,
  17% {
    background-position: 0% 0%;
    opacity: 0.88;
  }
  35%,
  39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
}

@keyframes holoCard {
  0%,
  100% {
    transform: translateX(-50%) rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: translateX(-50%) rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: translateX(-50%) rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: translateX(-50%) rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: translateX(-50%) rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}

/* Additional styles for owned and discounted items */
.itemPriceOwned {
  background: #00ffaa !important;
  color: white !important;
}

.itemPriceDiscounted {


}

@keyframes bounce {
  0% {  transform: translateX(00%)    }
  50%   {  transform: translateX(-100%) }
  100% {  transform: translateX(0%)    }
}
