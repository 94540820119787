button.oh {
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
}

.buttonContainer {
    position: relative;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width:fit-content;
}