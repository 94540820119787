.buttons {
  background: var(--primaryButton);
  color: #ffffff;

  padding: 3px 15px 5px 15px;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  line-height: 33px;
  letter-spacing: 0.00025em;
  font-size: 15px;
  font-weight: 500;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  z-index: 7;
  height: 40px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 5px;
  transition: background-color 0.1s;
  display: inline-flex;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.buttons:hover {
  background-color:var(--secondary); /* Red background on hover */
}

.DLbutton {
  border: 2px solid #000000;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #ffb101 25%, #000000 25%, #000000 50%, #ffb101 50%, #ffb101 75%, #000000 75%, #000000 100%);
  background-size: 50.91px 50.91px;
  padding: 3px 15px 5px 15px;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  line-height: 33px;
  letter-spacing: 0.00025em;
  font-size: 15px;
  font-weight: 500;
  text-shadow: rgb(0, 0, 0) 0px 2px 3px;
  z-index: 7;
  height: 40px;
  text-transform: uppercase;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 5px;
  transition: background-color 0.1s;
  display: inline-flex;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
}

.DLbutton:hover {
  border: 2px solid #ffb101;
}

#Button {

}



.dlpopup {
  height: 475px;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--primary);
  border-radius: 10px 10px 0 0;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4); /* Add a drop shadow */
  z-index: 20;
}


.dlpopup-content {
    border-radius: 10px;
    text-align: center;
    width: 400px;
    height: 445px;
    
}

.popup-image {
    width: 100%;
    height:100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.downloadtext {
  padding: 0 0 20px 0;
  text-align: center;
}

.footer-menu {
  position: relative;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #28abfb;
  border-right: 1px solid #262a49;
  border-bottom: 1px solid #262a49;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6); /* Add a drop shadow */
  /* Customize your footer menu style */
}


.timer {

}



.close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .close-btn svg {
    width: 100%;
    height: 100%;
    stroke-width: 5px;
  }

  .overlay {
    position: absolute; /* Changed from 'fixed' to 'absolute' */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure this is higher than other elements in the parent but lower than any popups or modals it should be under */
    backdrop-filter: blur(2px);
}


  .hotdog {
    font-size:100px;
    width: 300px;
    height: 300px;
    margin: 5px auto;

  }

  .dltext {
  
    font-size: xx-large;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  .dlcontent {
    margin: 0px auto;
  }

.dlButtonPos {
  bottom: 20px;
  left: 20px;
}