.sideMenu {
  max-width: 235px;
  height: 100%;
  padding: 0px;
  border-radius: 10px;
  background: var(--primary);
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  transition: 0.8s all;
  overflow: hidden;
  -webkit-transition: 0.8s all;
  width: 0px;
}

.navIcon {
  display: block;
  font-size: xxx-large;
  max-height: 30px;
  position: relative;
  bottom: 24px;
  left: -6px;
}

.sideMenu .logo {
  position: relative;
  text-decoration: none;
  display: flex; /* Changed from inline-flex to flex */
  align-items: center;
  color: #ffffff;
  font-size: 3.5em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 250px;
  min-width: 75px;
  width: 100%;
  height: auto; /* Ensure height adjusts to the content properly */
  max-height: 195px;
  transform: scale(1.0); /* Scale up the logo */
  transform-origin: center; /* Ensure scaling is centered */
  justify-content: center;
  padding: 0 5px 15px;
  transition: 1s;
}

.sideMenu .logo img {
  width: 100%; /* Make the image responsive to its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits well within the container */
}

.sideMenu .logopng {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-height: 178px;
  height: auto;
  text-align: center;
  padding-bottom: 20px;
}

.sideMenu .logopng img {
  max-height: 100%; /* Ensure the image scales with its container */
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  object-fit: contain; /* This makes the image scale properly within the container */
  height: auto; /* Maintain aspect ratio */
  width: auto; /* Maintain aspect ratio */
}

.sideMenu .sideMenuScroll {
  display: inline;
  max-width: 300px;
}

.sideMenu .nav {
  display: flex;
  width: 100%;
  list-style: none;
  padding-bottom: 20px;
  position: relative;
  padding-top: 10px;
  transition: 0.3s;
  gap: 10px;
}


.sideMenu .nav li a {
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px 20px;
  width: 93%;
  background-color: var(--primary);
  border: 1px solid var(--primaryButtonBorder);
  transition: 0.3s all;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.sideMenu li {
  width: inherit;
  height: 50px;
}

.sideMenu .nav li a:hover,
.sideMenu .nav li a.active {
  border: 1px solid var(--secondary);
  background: var(--secondary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4), 0 0 10px var(--secondary);
}

.sideMenu .social {
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center; /* Keeps items vertically centered */
  justify-content: center; /* Horizontally centers the flex items */
  list-style: none;
  gap: 20px;
  transition: 0.5s;
  padding-left: 0px;
}


.sideMenu .social li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  transition: 1s;
}

.sideMenu .social li a:hover {
  color: var(--secondary);
}

.sideMenu .social li .share {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  transition: 0.3s;
}

.sideMenu .social li .share:hover {
  background: var(--primary);
}

.sideMenu.active {
  width: 100%;
  max-width: 100px;
  transition: 1s;
  overflow: hidden;

}

.sideMenu.active .brand,
.sideMenu.active .navName {
  overflow: hidden;
}

.sideMenu.active .nav li a {
  padding: 5px;

}

.sideMenu.active .social {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




@media (max-width: 700px) {
  .sideMenu {
    width: 100%;
    max-width: 100px;
    transition: 1s;
    overflow: hidden;
  }


  .navIcon {
    display: block;
    font-size: xxx-large;
    max-height: 30px;
    position: relative;
    bottom: 22px;
  }  
  
  .sideMenu .brand,
  .sideMenu .navName {
    overflow: hidden;
  }
  
  .sideMenu .nav li a {
    padding: 5px;
  }
  
  .sideMenu .social {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.factoryButton2 {

  color: #fff !important;
}

.factoryButton2:hover {
  border: 1px solid var(--secondary);
}

.factoryButton2 .nav li a.active {
  border: 1px solid var(--secondary);
  background: var(--secondary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4), 0 0 10px var(--secondary);
}