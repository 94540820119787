.modalBackdrop {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.205);
    z-index: 10;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 10px;
}

.confThumb {
    width: 140px;
    border-radius: 10px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.7);

    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.confTitle {
    position: relative;
    bottom: 0px;

}

.confAdjust {
    padding-top: 50px;
}

button.yes,
button.no {
    width: 150px;
    display: inline-block;
    margin-right: 0px;

}

button.no {
    margin-left: 50px;
}



.buttonContainer {
    text-align: center;
    display: flex;

}

.centerNo {
    margin-left: 0px !important;
    margin-top: 20px;
}

.errorImg {
    filter:grayscale(1);
}

