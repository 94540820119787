.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 0px solid #28abfb;
  margin: 0 0 10px;
  padding: 0;
  margin-top: 5px;
}

.react-tabs__tab {
  display: inline-block;
  border: 0px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 8px;
  cursor: pointer;
  background-color: #2d3150;
  font-weight: bold;
  border-radius: 3px;
  margin: 2px;
  transition: background-color 0.2s;
}

.react-tabs__tab--selected {
  background: #28abfb;
  
  color: rgb(255, 255, 255);
  
  font-weight: bold;
}

.react-tabs__tab:hover {
  background: #4dbbff;
  
  color: rgb(255, 255, 255);
  
  font-weight: bold;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
  
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  
}

.react-tabs__tab-panel {
  display: none;
  background-color: #2d3150;
  border-radius: 8px;
  
}

.react-tabs__tab-panel--selected {
  display: block;
}

.headers {
  background-color:rgba(0, 0, 0, 0.171);
  padding: 5px;
  border-radius: 5px;
}