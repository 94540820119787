.toggle-switch {
  text-align: center;
  bottom: 15px;
  position: relative;
}

.pose-toggle {
  display: inline;
  position: relative;
  background-color: var(--primaryButton);
  border-radius: 10px;
  margin: 20px 0 20px 0;
  width: 90%;
  min-width: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.pose-label {
  margin-right: 5px;
  letter-spacing: 0.00025em;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  font-size: large;
  color: #fff;
  display: grid;
  left: 10px;
  top: 4px;
  position: relative;
  margin: 30px;
  display: inline-flex;
}

.poserContainer {
  display: inline-flex;
  width: 60px;
  height: 35px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  
}

.poserContainer input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--primary);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 100;
}

.pslider:before {
  position: absolute;
  content: "🙂";
  height: 26px;
  width: 26px;
  left: 0px;
  bottom: 11px;
  border-radius: 20px;
  font-size: x-large;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.pslider {
  background-color: var(--secondary);
  box-shadow: 
    0px 4px 6px rgba(0, 0, 0, 0.4),
    0 0 10px var(--secondary);
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #f44336, 0 0 10px #f44336, 0 0 15px #f44336, 0 0 20px #f44336;
  }

  100% {
    box-shadow: 0 0 10px #f44336, 0 0 20px #f44336, 0 0 30px #f44336, 0 0 40px #f44336;
  }
}

input:focus+.pslider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.pslider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  font-size: x-large;
  content: "😎";
  bottom: 11px;
}


.hiddenbuttons {
  background: transparent;
  transition: background-color 0.3s ease;

  font-size: clamp(0.3rem, 2vw, 1rem);
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  text-align: center;
  color: #ffffff;
  z-index: 7;
  border-radius: 5px;
  text-transform: uppercase;
  padding-bottom: 10px;
  padding-top: 5px;
}


.hiddenbuttons:hover {
  cursor: default;
  border-left: 0px solid #ffffff;
  border-top: 0px solid #ffffff;
  border-right: 0px solid #ffffff;
  border-bottom: 0px solid #ffffff;
  /*box-shadow: 0 0 5px rgb(26, 233, 136);*/
  color: rgb(255, 255, 255);
}