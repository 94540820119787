.factoryMenu {
  max-width: 235px;
  height: 100%;
  padding: 14px;
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: var(--primary);
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6); /* Add a drop shadow */
  white-space: nowrap;
  transition: 0.8s;

}

.factnavIcon {
  display: block;
  font-size: xxx-large;
  max-height: 30px;
  position: relative;
  bottom: 23px;
  left: -6px;
}

.factoryMenu .logo {
  position: relative;
  text-decoration: none;
  display: flex; /* Changed from inline-flex to flex */
  align-items: center;
  gap: 15px;
  row-gap: 15px;
  color: #ffffff;
  font-size: 3.5em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 250px;
  min-width: 75px;
  width: 100%;
  height: auto; /* Ensure height adjusts to the content properly */
  max-height: 195px;
  transform: scale(1.0); /* Scale up the logo */
  transform-origin: center; /* Ensure scaling is centered */
  justify-content: center;
  padding: 0 5px 15px;
  transition: 1s;
}

.factoryMenu .logo img {
  width: 100%; /* Make the image responsive to its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits well within the container */
}


.factoryMenu .logopng {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-height: 178px;
  height: auto;
  text-align: center;
  padding-bottom: 20px;
}

.factoryMenu .logopng img {
  max-height: 100%; /* Ensure the image scales with its container */
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  object-fit: contain; /* This makes the image scale properly within the container */
  height: auto; /* Maintain aspect ratio */
  width: auto; /* Maintain aspect ratio */
}

.factoryMenu .factoryScroll {
  display: inline;
  max-width: 300px;
  padding-top: 2px;
  
  }

.factoryMenu .fnav {
  display: flex;
  width: 100%;
  list-style: none;
  padding-bottom: 20px;
  position: relative;
  gap: 10px;
  flex-flow: column;
  padding-left: 0px;

}

.factoryMenu .fnav li a {
  text-decoration: none;
  text-transform: capitalize;
  color: #ffffff;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center; /* Keeps items centered vertically */
  gap: 25px;
  border-radius: 10px;
  padding: 10px 20px;
  width: 93%;
  background-color: var(--primary);
  transition: 0.3s;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  border: 1px solid #00000000;
}

.factoryMenu li {
  width: inherit;
  height: 50px;
}

.factoryMenu .fnav li a:hover {
  border: 1px solid var(--primaryButtonBorder);

}



.fnav li.active a {
  border: 1px solid var(--secondary);
  background: var(--primaryButton);
}


.factoryMenu .social {
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center; /* Keeps items vertically centered */
  justify-content: center; /* Horizontally centers the flex items */
  list-style: none;
  gap: 20px;
  transition: 0.5s;
  padding-left: 0px;
}

.factoryMenu .social li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  transition: 0.3s;
}

.factoryMenu .social li a:hover {
  color: var(--primaryButtonBorder);
}

.factoryMenu .social li .share {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  transition: 0.3s;
}

.factoryMenu .social li .share:hover {
  background: var(--primary);
}

.factoryMenu.active {
  width: 100%;
  max-width: 100px;
  transition: 1s;
  overflow: hidden;
}

.factoryMenu.active .brand,
.factoryMenu.active .fnavName {
  overflow: hidden;
}

.factoryMenu.active .fnav li a {
  padding: 5px;
}

.factoryMenu.active .social {
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .factoryMenu {
    width: 100%;
    max-width: 100px;
    transition: 1s;
    overflow: hidden;
  }

  .factnavIcon {
    display: block;
    font-size: xxx-large;
    max-height: 30px;
    position: relative;
    bottom: 22px;
  }

  .factoryMenu .brand,
  .factoryMenu .fnavName {
    overflow: hidden;
  }

  .factoryMenu .fnav li a {
    padding: 5px;
  }

  .factoryMenu .social {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.factoryMenu button {
  /* This line now targets both <a> and <button> elements */
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  gap: 25px;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  background-color: var(--primaryButton);
  border: 1px solid var(--primaryButtonBorder);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
  transition: 1s;
}

.factoryMenu button:hover {
  background: var(--shopHighlight);
  color: #2c2c2c;
}

.storeButton {
  background: var(--shop) !important;
  color: #2c2c2c !important;
}

.nameleft {
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
}

.nametitle {
  letter-spacing: 0.00025em;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  font-weight: 300;
  font-size: large;
  color: #fff;
  display: grid;
  /* Ensures the content inside .nametitle uses grid layout */
  place-items: center;
  /* Shorthand for align-items and justify-items */
  overflow: hidden;
  padding-top: 0px;
}

.nametitle.active {
  text-align: center;
  overflow: hidden;
}


.name-text {
  font: inherit;
  background-color: transparent;
  border: 0;
  padding: 0px 5px 0px 5px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  right: 5px;
}

.fnavName.hidden {
  
}

