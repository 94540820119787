:root {
  --color1: #ec9bb6;
  --color2: #ccac6f;
  --color3: #69e4a5;
  --color4: #8ec5d6;
  --color5: #b98cce;

}

.coinContainer {
  width: 240px;
  height: 165px;
  margin: 5px;
  position: relative;
  display: inline-block;
  perspective: 1000px;
  /* Ensures proper 3D perspective */
  --sparkles: url("../../../public/assets/games/sparkles.webp");
  --foil1: url("../../../public/assets/foils/holo (4).jpg");
  --foil2: url("../../../public/assets/foils/holo (12).jpg");
  --mask: url("../../../public/assets/games/glitter.png");
  --shine: url("../../../public/assets/games/shine.png");
  --holo: url("../../../public/assets/games/holo.webp");
  --grain: url("../../../public/assets/textures/grain.svg");
  --metal: url("../../../public/assets/textures/metalShine.jpg");
  --swirls: url("../../../public/assets/textures/swirls.jpg");
  --circuit: url("../../../public/assets/textures/circuit.png");
  --borderFoil: url("../../../public/assets/foils/holo (10).jpg");
  padding: 0 !important;
  z-index: 900 !important;
  transform: translateZ(0) !important;

}

.shine {
  background-image: var(--shine);
  background-size: 100%;
  width: 300px;
  height: 300px;
  z-index: 200;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

.coinBorderFoil {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  opacity: 1;
  background-image: var(--swirls);
  /* Base image */
  background-size: 100px;
  mix-blend-mode: multiply;
  filter: brightness(1);
  z-index: 1;
}

.coinBorder {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  filter: saturate(1.2) brightness(1.1) contrast(1.2);
  opacity: 1;
  background-image: var(--metal);
  /* Base image */
  background-size: 350px;
}

.coinBorder::before {
  content: "";
  position: absolute;
  inset: 0;
  background-size: 100%;
  background-image: var(--sparkles);
  mix-blend-mode: soft-light;
  opacity: 1;
  /* Adjust opacity for blending effect */
  pointer-events: none;
  /* Ensure it doesn’t interfere with interactions */
  z-index: 1;
  filter: brightness(3);
}

.coinImgContainer {
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow: hidden;
  border: 0px solid #b3b3b3;
  border-radius: 10px;
  z-index: 6;
}

.coinImgContainer::before {
  content: "";
  /* Required for pseudo-elements */
  position: absolute;
  inset: 0;
  /* Covers the .coinImg */
  background-image: var(--grain);
  /* Replace with your overlay image */
  background-size: 100%;
  /* Ensures the image fits properly */
  background-position: center;
  /* Centers the image */
  
  /* Blend mode for effects */
  z-index: 1;
  /* Places it above the .coinImg */
  opacity: 0.2;
  /* Adjust opacity if needed */
  pointer-events: none;
  /* Ensures it doesn’t interfere with interactions */

}




.coinImg {
  object-fit: cover;
  border-radius: 10px;
  border: solid 0px #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  filter: saturate(1.5) brightness(1.2) contrast(1.8);
  max-width: 300px;
  max-height: 300px;
}

.coinDescription,
.coinTitle,
.coinPrice {
  position: absolute;
  z-index: 900 !important;
  background-color: #00000000;
  color: rgb(255, 255, 255);
  text-align: center;

}

.coinDescriptionBlur,
.coinTitleBlur,
.coinPriceBlur {
  position: absolute;
  z-index: 800 !important;
  background-color: #00000000;
  color: rgb(0, 0, 0);
  text-align: center;
  filter: blur(4px);
}

.coinDescription {
  position: absolute;
  /* Ensure the element can be positioned */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
  /* Center horizontally and vertically */
  width: 100%;
  height: 100%;
  font-size: 1.8em;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  /* Optional, for better control */
  justify-content: center;
  /* Horizontal centering of text */
  align-items: center;
  /* Vertical centering of text */
}

.coinTitle {
  position: absolute;
  /* Ensure the element can be positioned */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
  /* Center horizontally and vertically */
  width: 100%;
  height: 100%;
  font-size: 3.5em;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  /* Optional, for better control */
  justify-content: center;
  /* Horizontal centering of text */
  align-items: center;
  /* Vertical centering of text */
}


.coinPrice {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  border-radius: 10px 10px 0 0px;
  padding: 0px 0;
  font-size: 1.8rem;
  z-index: 2;
}

.coinDescriptionBlur {
  position: absolute;
  /* Ensure the element can be positioned */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
  /* Center horizontally and vertically */
  width: 100%;
  height: 100%;
  font-size: 1.8em;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  /* Optional, for better control */
  justify-content: center;
  /* Horizontal centering of text */
  align-items: center;
  /* Vertical centering of text */
}

.coinTitleBlur {
  position: absolute;
  /* Ensure the element can be positioned */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
  /* Center horizontally and vertically */
  width: 100%;
  height: 100%;
  font-size: 3.5em;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  /* Optional, for better control */
  justify-content: center;
  /* Horizontal centering of text */
  align-items: center;
  /* Vertical centering of text */
}

.coinPriceBlur {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  border-radius: 10px 10px 0 0px;
  padding: 0px 0;
  font-size: 1.8rem;
  z-index: 2;
}

.coinCard {
  --space: 5%;
  position: relative;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  border: 1px solid #686868;
  background-image: var(--foil2);
  background-blend-mode: color-dodge;
  background-color: #666666;
  transform-origin: center;
  will-change: transform, filter;
  cursor: pointer;
  box-shadow: 0 0 10px 2px #000000a2;
  z-index: 900 !important;
  transform: translateZ(0);
  transition: opacity 1s ease;
  /*Smooth fade for entire card if needed */
  left: 50%;
  translate: -50%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    opacity: 0.2;
    mix-blend-mode: color-dodge;
    pointer-events: none;
    transition: opacity 6s ease;
    /* Ensure smooth fade on leave */

  }

  &:before {
    background-position: var(--posX, 50%) var(--posY, 50%);
    background-size: 300% 300%;
    background-image: linear-gradient(115deg,
        transparent 0%,
        var(--color1) 25%,
        transparent 47%,
        transparent 53%,
        var(--color2) 75%,
        transparent 100%);
    filter: brightness(0.8) contrast(1);
    z-index: 5;

  }

  &:after {
    background-image: linear-gradient(125deg,
        rgba(255, 0, 132, 0.31) 15%,
        rgba(252, 164, 0, 0.25) 30%,
        rgba(255, 255, 0, 0.19) 40%,
        rgba(0, 255, 138, 0.13) 60%,
        rgba(0, 207, 255, 0.25) 70%,
        rgba(204, 76, 250, 0.31) 85%);
    background-position: var(--posX, 50%) var(--posY, 50%);
    background-size: 160%;
    opacity: 0.8;
    /* Default opacity */
    z-index: 5;
    transition: opacity 6s ease;
  }

  &:hover,
  &.active {
    /* No specific rules for hover container */
  }

  &:hover:before,
  &.active:before {
    --space: 5%;
    background-image: repeating-linear-gradient(-33deg,
        rgb(255, 119, 115) calc(var(--space) * 1),
        rgba(255, 237, 95, 1) calc(var(--space) * 2),
        rgba(168, 255, 95, 1) calc(var(--space) * 3),
        rgba(131, 255, 247, 1) calc(var(--space) * 4),
        rgba(120, 148, 255, 1) calc(var(--space) * 5),
        rgb(216, 117, 255) calc(var(--space) * 6),
        rgb(255, 119, 115) calc(var(--space) * 7));
    opacity: 0.5;
    
    /* Increase opacity on hover */
    transition: opacity 1s ease;
    mix-blend-mode: color-dodge;
    z-index: 10;
  }

  &:hover:after,
  &.active:after {
    opacity: 1;
    /* Fully visible on hover */
    transition: opacity 1s ease;
    
  }

  &:not(:hover):before,
  &:not(:hover):after {
    opacity: 0.8;
    /* Fade back down after hover */
    transition: opacity 6s ease;
  }

  &.animated {
    transition: none;
    animation: holoCard 12s ease 0s 1;

    &:before {
      transition: none;
      animation: holoGradient 12s ease 0s 1;
    }

    &:after {
      transition: none;
      animation: holoSparkle 12s ease 0s 1;
    }
  }
}


/* Keyframe animations (copy from the template) */
@keyframes holoSparkle {

  0%,
  100% {
    opacity: .75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }

  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(.8) contrast(1.2);
  }

  13%,
  16% {
    opacity: .5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(.8);
  }

  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }

  55% {
    opacity: .33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes holoGradient {

  0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(.5) contrast(1);
  }

  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }

  13%,
  17% {
    background-position: 0% 0%;
    opacity: .88;
  }

  35%,
  39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(.5) contrast(1);
  }

  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(.75) contrast(1.25);
  }
}

@keyframes holoCard {

  0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }

  5%,
  8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }

  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }

  35%,
  38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }

  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}